body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins,
div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
  color: #333;
}
a:hover {
  text-decoration: underline;
  color: #333;
}
ul,
li {
  list-style: none;
}
button {
  outline: none;
  border: none;
}
body,
textarea,
select,
input,
button {
  font-size: 12px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f5f5f5;
}
.wrap-v1 {
  width: 1100px;
  margin: 0 auto;
}
.wrap-v2 {
  width: 980px;
  margin: 0 auto;
}
.sprite_01 {
  background: url(../img/sprite_01.png) no-repeat 0 9999px;
}
.sprite_02 {
  background: url(../img/sprite_02.png) no-repeat 0 9999px;
}
.sprite_cover {
  background: url(../img/sprite_cover.png) no-repeat 0 9999px;
}
.sprite_icon {
  background: url(../img/sprite_icon.png) no-repeat 0 9999px;
}
.sprite_icon2 {
  background: url(../img/sprite_icon2.png) no-repeat 0 9999px;
}
.sprite_icon3 {
  background: url(../img/sprite_icon3.png) no-repeat 0 9999px;
}
.sprite_button {
  background: url(../img/sprite_button.png) no-repeat 0 9999px;
}
.sprite_button2 {
  background: url(../img/sprite_button2.png) no-repeat 0 9999px;
}
.sprite_table {
  background: url(../img/sprite_table.png) no-repeat 0 9999px;
}
.sprite_playbar {
  background: url(../img/playbar_sprite.png) no-repeat 0 9999px;
}
.sprite_playlist {
  background: url(../img/playlist_sprite.png) no-repeat 0 9999px;
}
