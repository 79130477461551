body, html, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, header, menu, section, p, input, td, th, ins, div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: underline;
  color: #333;
}

a:hover {
  text-decoration: underline;
  color: #333;
}

ul, li {
  list-style: none;
}
button {
  outline: none;
  border: none;
}